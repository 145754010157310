var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Mailroom Packages: Add Package")]),_c('v-spacer'),_c('send-notice',{ref:"sendNoticeDialog",attrs:{"notice-ids":_vm.noticeIds,"button-text":"Send Notifications Now"}})],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-stepper',{model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.step > 1,"step":"1"}},[_vm._v("User")]),_c('v-stepper-step',{attrs:{"complete":_vm.step > 2,"step":"2"}},[_vm._v("Package Type")]),_c('v-stepper-step',{attrs:{"step":"3"}},[_vm._v("Location")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',[_c('v-card-text',[_c('directory-search',{ref:"addUserSearch",attrs:{"search-label":"User Search","valueField":"pidm","includeBoxNumber":"","nameFormatLastFirst":""},on:{"select":_vm.selectUser}}),_c('v-text-field',{ref:"barcodeField",attrs:{"label":"Barcode","outlined":"","error-messages":_vm.barcodeErrors},on:{"change":_vm.barcodeChanged,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }_vm.proceedAfterValidate = true}},model:{value:(_vm.add.barcode),callback:function ($$v) {_vm.$set(_vm.add, "barcode", $$v)},expression:"add.barcode"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"disabled":(_vm.add.user.name == null || _vm.add.user.name === '') && _vm.add.barcode === '',"color":"primary"},on:{"click":_vm.next}},[_vm._v("Next")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.clearForm}},[_vm._v("Reset")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',[_c('v-list',_vm._l((_vm.typeOptions),function(ref,index){
var text = ref.text;
return _c('v-list-item',{key:'type-option-' + index,on:{"click":function($event){return _vm.next({ packageType: text })}}},[_c('v-list-item-avatar',{attrs:{"color":_vm.colors[index % _vm.colors.length]}},[_vm._v(_vm._s(text.substr(0, 1)))]),_c('v-list-item-title',[_vm._v(_vm._s(text))])],1)}),1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.clearForm}},[_vm._v("Reset")])],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"3"}},[_c('v-card',[_c('v-list',[_c('v-subheader',[_vm._v("Recommended Location")]),_vm._l((_vm.recommendedLocations),function(ref,index){
var text = ref.text;
var packageCount = ref.packageCount;
return _c('v-list-item',{key:'rec-location-option-' + index,on:{"click":function($event){return _vm.next({ location: text })}}},[_c('v-list-item-avatar',{attrs:{"color":_vm.colors[index % _vm.colors.length]}},[_vm._v(_vm._s(text.substr(0, 4) === 'Rack' ? text.substr(8, 1) : text.substr(0, 1)))]),_c('v-list-item-title',[_vm._v(_vm._s(text))]),(packageCount > 0)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({attrs:{"color":_vm.colors[index % _vm.colors.length]}},on),[_vm._v(_vm._s(packageCount))])]}}],null,true)},[_c('span',[_vm._v("Number of packages "+_vm._s(_vm.add.user.name)+" currently has in this location")])])],1):_vm._e()],1)}),_c('v-subheader',[_vm._v("All Locations")]),_vm._l((_vm.locationOptions),function(ref,index){
var text = ref.text;
return _c('v-list-item',{key:'location-option-' + index,on:{"click":function($event){return _vm.next({ location: text })}}},[_c('v-list-item-avatar',{attrs:{"color":_vm.colors[index % _vm.colors.length]}},[_vm._v(_vm._s(text.substr(0, 4) === 'Rack' ? text.substr(8, 1) : text.substr(0, 1)))]),_c('v-list-item-title',[_vm._v(_vm._s(text))]),(_vm.locationPackageCount(text) > 0)?_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-avatar',_vm._g({attrs:{"color":_vm.colors[index % _vm.colors.length]}},on),[_vm._v(_vm._s(_vm.locationPackageCount(text)))])]}}],null,true)},[_c('span',[_vm._v("Number of packages "+_vm._s(_vm.add.user.name)+" currently has in this location")])])],1):_vm._e()],1)})],2),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":_vm.clearForm}},[_vm._v("Reset")])],1)],1)],1)],1)],1)],1)],1),_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v("Added packages")]),_c('v-list',{staticStyle:{"height":"600px","overflow":"hidden","overflow-y":"auto"}},_vm._l((_vm.recentPackages),function(ref,index){
var barcode = ref.barcode;
var userName = ref.user.name;
var packageType = ref.packageType;
var location = ref.location;
var count = ref.count;
return _c('v-list-item',{key:'package-' + index},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(userName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(barcode))])],1),_c('v-list-item-content',[_c('v-list-item-subtitle',[_vm._v(_vm._s(packageType))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(location))])],1),_c('v-list-item-action',[_c('v-avatar',{attrs:{"color":_vm.colors[count]}},[_vm._v(_vm._s(count)),_c('span',{staticStyle:{"font-size":".6em","margin-top":"-8px"}},[_vm._v(_vm._s(count === 1 ? 'st' : (count === 2 ? 'nd' : (count === 3 ? 'rd' : 'th'))))])])],1)],1)}),1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }