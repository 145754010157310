<template>
  <v-card>
    <!-- <admin-header></admin-header> -->
    <v-toolbar>
      <v-toolbar-title>Mailroom Packages: Add Package</v-toolbar-title>
      <v-spacer></v-spacer>
      <send-notice ref="sendNoticeDialog" :notice-ids="noticeIds" button-text="Send Notifications Now"></send-notice>
    </v-toolbar>
    <v-row>
      <v-col>
        <v-card>
          <v-stepper v-model="step">
            <v-stepper-header>
              <v-stepper-step :complete="step > 1" step="1">User</v-stepper-step>
              <v-stepper-step :complete="step > 2" step="2">Package Type</v-stepper-step>
              <v-stepper-step step="3">Location</v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content step="1">
                <v-card>
                  <v-card-text>
                    <directory-search ref="addUserSearch" search-label="User Search" valueField="pidm" includeBoxNumber nameFormatLastFirst @select="selectUser"></directory-search>
                    <v-text-field ref="barcodeField" v-model="add.barcode" label="Barcode" outlined :error-messages="barcodeErrors" @change="barcodeChanged" @keydown.enter="proceedAfterValidate = true"></v-text-field>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn :disabled="(add.user.name == null || add.user.name === '') && add.barcode === ''" color="primary" @click="next">Next</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="clearForm">Reset</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="2">
                <v-card>
                  <v-list>
                    <v-list-item v-for="({ text }, index) in typeOptions" :key="'type-option-' + index" @click="next({ packageType: text })">
                      <v-list-item-avatar :color="colors[index % colors.length]">{{ text.substr(0, 1) }}</v-list-item-avatar>
                      <v-list-item-title>{{ text }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="clearForm">Reset</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
              <v-stepper-content step="3">
                <v-card>
                  <v-list>
                    <v-subheader>Recommended Location</v-subheader>
                    <v-list-item v-for="({ text, packageCount }, index) in recommendedLocations" :key="'rec-location-option-' + index" @click="next({ location: text })">
                      <v-list-item-avatar :color="colors[index % colors.length]">{{ text.substr(0, 4) === 'Rack' ? text.substr(8, 1) : text.substr(0, 1) }}</v-list-item-avatar>
                      <v-list-item-title>{{ text }}</v-list-item-title>
                      <v-list-item-action v-if="packageCount > 0">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-avatar v-on="on" :color="colors[index % colors.length]">{{ packageCount }}</v-avatar>
                          </template>
                          <span>Number of packages {{ add.user.name }} currently has in this location</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                    <v-subheader>All Locations</v-subheader>
                    <v-list-item v-for="({ text }, index) in locationOptions" :key="'location-option-' + index" @click="next({ location: text })">
                      <v-list-item-avatar :color="colors[index % colors.length]">{{ text.substr(0, 4) === 'Rack' ? text.substr(8, 1) : text.substr(0, 1) }}</v-list-item-avatar>
                      <v-list-item-title>{{ text }}</v-list-item-title>
                      <v-list-item-action v-if="locationPackageCount(text) > 0">
                        <v-tooltip top>
                          <template v-slot:activator="{ on }">
                            <v-avatar v-on="on" :color="colors[index % colors.length]">{{ locationPackageCount(text) }}</v-avatar>
                          </template>
                          <span>Number of packages {{ add.user.name }} currently has in this location</span>
                        </v-tooltip>
                      </v-list-item-action>
                    </v-list-item>
                  </v-list>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" @click="clearForm">Reset</v-btn>
                  </v-card-actions>
                </v-card>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card>
      </v-col>
      <v-col>
        <v-card>
          <v-card-title>Added packages</v-card-title>
          <v-list style="height:600px;overflow:hidden;overflow-y:auto">
            <v-list-item v-for="({ barcode, user: { name: userName }, packageType, location, count }, index) in recentPackages" :key="'package-' + index">
              <v-list-item-content>
                <v-list-item-title>{{ userName }}</v-list-item-title>
                <v-list-item-subtitle>{{ barcode }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-content>
                <v-list-item-subtitle>{{ packageType }}</v-list-item-subtitle>
                <v-list-item-subtitle>{{ location }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-avatar :color="colors[count]">{{ count }}<span style="font-size:.6em;margin-top:-8px">{{ count === 1 ? 'st' : (count === 2 ? 'nd' : (count === 3 ? 'rd' : 'th')) }}</span></v-avatar>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import { ref, reactive, computed, onMounted, onUnmounted } from '@vue/composition-api'
import { stringFormatDate } from '../../../../helpers/formatters'

export default {
  components: {
    directorySearch: () => import('@/components/greatscots/searchField'),
    sendNotice: () => import('@/components/mailroom/admin/sendNotice')
    // Pickup: () => import('@/components/mailroom/admin/pickup')
  },
  setup (props, { root }) {
    const packageServ = root.$feathers.service('mailroom/package')
    const addUserSearch = ref(null)
    const barcodeField = ref(null)
    const recommendedLocations = ref([])
    const userPackageLocations = ref([])
    async function selectUser ({ text, subtext, value }) {
      if (value == null || value === '' || subtext == null) return
      subtext = subtext.split(' - ')[0]
      add.user.pidm = value
      add.user.name = text
      add.user.person = subtext
      // Look for any other packages for the same user
      const { data } = await packageServ.find({ query: { 'user.pidm': value, status: { $in: ['Received', 'Ready for Pickup'] }, $distinct: 'location' } })
      recommendedLocations.value = []
      for (const { _id, total } of data) {
        recommendedLocations.value.push({ text: _id, packageCount: total })
      }
      if (recommendedLocations.value.length === 0) {
        if (subtext === 'Student') {
          for (let i = 0; i < locationOptions.value.length; i++) {
            let loc = locationOptions.value[i].text
            if (loc.substr(0, 5) === 'Rack ') {
              let temp = loc.substr(loc.indexOf('(') + 1)
              temp = temp.substr(0, temp.length - 1)
              const regex = new RegExp('[' + temp + ']', 'ig')
              // console.log(temp, text.substr(0, 1), regex.test(text.substr(0, 1)))
              if (regex.test(text.substr(0, 1))) {
                // console.log(loc)
                recommendedLocations.value.push({ text: loc, packageCount: 0 })
              }
            }
          }
        } else {
          // Check to see if they are Athletics or Library; in those cases, put them on the Athletics Rack or Library Bin; we will also add Employee Rack as a second recommended option
          const { data: userData } = await root.$feathers.service('directory/people').find({ query: { pidm: add.user.pidm } })
          if (userData.length > 0) {
            for (const { location: { department } } of userData) {
              if (department === 'Athletics') recommendedLocations.value.push({ text: 'Athletics Rack', packageCount: 0 })
              else if (department === 'Library') recommendedLocations.value.push({ text: 'Library Bin', packageCount: 0 })
            }
          }
          recommendedLocations.value.push({ text: 'Employee Rack', packageCount: 0 })
        }
      }
      if (barcodeField.value) barcodeField.value.focus()
    }
    function locationPackageCount (location) {
      for (let i = 0; i < userPackageLocations.value.length; i++) {
        if (userPackageLocations.value[i].location === location) return userPackageLocations.value[i].packageCount
      }
      return 0
    }
    const step = ref(1)
    const add = reactive({
      barcode: '',
      user: {
        user: '',
        name: '',
        person: ''
      },
      packageType: '',
      location: ''
    })
    const proceedAfterValidate = ref(false)
    const barcodeErrors = ref([])
    const barcodeValidated = ref(false)
    function barcodeChanged (barcode) {
      packageServ.find({ query: { barcode, $limit: 0 } }).then(({ total }) => {
        barcodeErrors.value = []
        if (total > 0) {
          barcodeErrors.value.push('Barcode already exists in the database')
          barcodeValidated.value = false
          proceedAfterValidate.value = false
        } else {
          barcodeValidated.value = true
          if (proceedAfterValidate.value) next({})
        }
      })
    }
    const colors = ref(['red white--text', 'pink white--text', 'purple white--text', 'deep-purple white--text', 'indigo white--text', 'blue white--text', 'light-blue white--text', 'cyan white--text', 'teal white--text', 'green white--text', 'light-green', 'lime', 'yellow', 'amber', 'orange', 'deep-orange white--text'])
    const recentPackages = ref([])
    const formEmpty = computed(() => {
      return add.barcode === '' && add.user.pidm === '' && (add.user.name === '' || add.user.name == null) && (add.user.person === '' || add.user.person == null) && add.packageType === '' && add.location === ''
    })
    const formComplete = computed(() => {
      return add.barcode !== '' && add.user.name !== '' && add.user.person !== '' && add.packageType !== '' && add.location !== ''
    })
    function clearForm () {
      add.barcode = ''
      add.user = { pidm: '', name: '', person: '' }
      add.packageType = ''
      add.location = ''
      addUserSearch.value.clear()
      userPackageLocations.value = []
      barcodeErrors.value = []
      barcodeValidated.value = false
      step.value = 1
      root.$nextTick(() => addUserSearch.value.$children[0].focus())
    }
    function next ({ packageType, location }) {
      if (packageType) {
        add.packageType = packageType
        step.value++
      } else if (location) {
        add.location = location
        addPackage()
      } else {
        if (add.barcode !== '' && add.user.name !== '' && step.value === 1 && barcodeErrors.value.length === 0 && barcodeValidated.value) {
          step.value++
        }
      }
    }
    async function addPackage () {
      try {
        const data = await packageServ.create(add)
        // console.log(data)
        clearForm()
        let loc = recommendedLocations.value.filter(({ text }) => text === data.location)
        data.count = loc.length > 0 ? loc[0].packageCount + 1 : 1
        recentPackages.value.splice(0, 0, data)
        if (recentPackages.value.length > 10) recentPackages.value.splice(10, 1)
        root.$store.dispatch('main/snackbar', { active: true, color: 'success', timeout: 6000, text: 'Package Added' })
      } catch (e) {
        root.$store.dispatch('main/snackbar', { active: true, color: 'error', timeout: 10000, text: 'Error adding package: ' + e })
      }
    }

    const statusOptions = ref([])
    const locationOptions = ref([])
    const typeOptions = ref([])
    const interval = ref(null)

    onMounted(async () => {
      const optionService = root.$feathers.service('forms/select-options')
      optionService.find({ query: { name: 'Mailroom: Package Status' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            statusOptions.value = options
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Location' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            locationOptions.value = options
          })
        }
      })
      optionService.find({ query: { name: 'Mailroom: Package Type' } }).then(({ data }) => {
        if (data.length > 0) {
          optionService.get(data[0].value).then(({ options }) => {
            typeOptions.value = options
          })
        }
      })
      interval.value = setInterval(() => { refresh() }, 10000)
      refresh()
      const { data } = await root.$feathers.service('mailroom/notification').find({ query: { active: true, 'send.auto': false } })
      for (const { _id } of data) {
        noticeIds.value.push(_id)
      }
      setTimeout(() => {
        addUserSearch.value.$children[0].focus()
      }, 500)
    })
    onUnmounted(() => {
      clearInterval(interval.value)
    })

    const noticeIds = ref([])
    const sendNoticeDialog = ref()
    async function refresh () {
      root.$store.commit('updateActivity')
      if (sendNoticeDialog.value != null) {
        sendNoticeDialog.value.refresh()
      }
    }

    return {
      addUserSearch,
      recommendedLocations,
      userPackageLocations,
      selectUser,
      locationPackageCount,
      step,
      add,
      proceedAfterValidate,
      barcodeField,
      barcodeErrors,
      barcodeValidated,
      barcodeChanged,
      recentPackages,
      colors,
      formEmpty,
      formComplete,
      clearForm,
      next,
      addPackage,
      statusOptions,
      locationOptions,
      typeOptions,
      noticeIds,
      sendNoticeDialog,
      refresh,
      stringFormatDate
    }
  }
}
</script>
